:root {
  --marbles-background-rgb: 26, 30, 45;
  --marbles-container-dark-blue: 37, 41, 55;

  --marbles-light-dark-blue: 50, 52, 66;

  --marbles-dark-blue: 26, 30, 45;

  --marbles-yellow: 255, 199, 3;

  --primary-text-color-light: 227, 228, 230;
  --primary-text-color-dark: 150, 151, 153;

  // COLORS for MARBLES

  --blue: 57, 199, 236;

  --yellow: 255, 199, 3;
  --light-yellow: 246, 225, 58;

  --green: 81, 255, 90;
  --dark-green: 56, 179, 63;

  --red: 244, 54, 52;

  --purple: 208, 44, 240;
  --pink: 246, 50, 127;
  --orange: 255, 152, 62;
  --dark-orange: 248, 85, 30;

  --teal: 0, 128, 128;
  --magenta: 255, 0, 255;
  --lime: 0, 255, 0;
  --cyan: 0, 255, 255;
  --coral: 255, 127, 80;
  --turquoise: 64, 224, 208;
  --gold: 255, 215, 0;
  --lavender: 230, 230, 250;
  --chocolate: 210, 105, 30;
  --indigo: 75, 0, 130;
  --khaki: 240, 230, 140;
  --maroon: 128, 0, 0;
  --navy: 0, 0, 128;
  --olive: 128, 128, 0;
  --peru: 205, 133, 63;
  --plum: 221, 160, 221;
  --sienna: 160, 82, 45;
  --tomato: 255, 99, 71;
  --violet: 238, 130, 238;
  --wheat: 245, 222, 179;
  --crimson: 220, 20, 60;
  --firebrick: 178, 34, 34;

  // COLOR FOR GEN 1 MARBLES
  --midnight: 48, 150, 217;
  --ocean: 125, 226, 234;
  --atlas: 93, 184, 96;
  --nemo: 232, 97, 47;
  --princess: 225, 197, 215;
  --inferno: 255, 87, 65;
  --toothpaste: 233, 233, 233;
  --bee: 224, 202, 0;

  // NEW DARK COLOR SCHEME
  --black-color: 0, 0, 0;
  --white-color: 255, 255, 255;

  --primary-component-background: 37, 37, 37;
  --primary-dark-color: 26, 26, 26;
  --primary-background-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.6) 0px 12px 48px 0px;
  --primary-background-shadow-with-inset: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.6) 0px 12px 48px 0px;
  --primary-background-shadow-with-bottom-inset: 0px 4px 0px 0px rgba(26, 26, 26, 1),
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.6) 0px 12px 48px 0px;
  --primary-backdrop-filter: blur(18px) saturate(1.8);
  --primary-backdrop-filter-light: blur(6px) saturate(1.8);
}

.formula-font-enabled,
.formula-font-enabled.lang-en-soc,
.formula-font-enabled.lang-en {
  --font-formula-stack: var(--font-formula), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Arial, sans-serif;
}

.formula-font-enabled.lang-da,
.formula-font-enabled.lang-tr,
.formula-font-enabled.lang-th,
.formula-font-enabled.lang-fr,
.formula-font-enabled.lang-ru,
.formula-font-enabled.lang-ja,
.formula-font-enabled.lang-es,
.formula-font-enabled.lang-pt,
.formula-font-enabled.lang-de,
.formula-font-enabled.lang-zh,
.formula-font-enabled.lang-hi,
.formula-font-enabled.lang-ko,
.formula-font-enabled.lang-vi,
.formula-font-enabled.lang-ar {
  --font-formula-stack: var(--font-inter), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Arial, sans-serif;
}

body {
  background: rgb(var(--black-color));
  overflow: hidden;
}

.marbles-game_outer {
  @media screen and (pointer: fine) {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
}

.marbles-game {
  box-sizing: border-box;

  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  scroll-behavior: smooth;
  user-select: none;

  overflow: hidden;
  height: 100dvh;

  background: rgb(24, 24, 27) url('/assets/marbles-lobby-placeholder.webp') no-repeat center center;
  background-size: cover;

  @media screen and (pointer: coarse) {
    overflow: scroll;
  }

  @media screen and (pointer: fine) {
    width: 100%;
    max-height: 100dvh;
    height: auto;
    margin: 0 auto;
    aspect-ratio: 16 / 9;

    .marbles-game__container-inner {
      transform-origin: top left;
      width: 100%;
      min-width: 1120px;

      aspect-ratio: 16 / 9;

      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      overflow: scroll;

      // hide scrollbar
      &::-webkit-scrollbar {
        display: none;
      }

      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      .marbles-game_scroll-container {
        scroll-behavior: smooth;
        aspect-ratio: 16 / 9;
        display: flex;
        align-items: flex-start;
        max-width: 1846px;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

  .track-hero {
    max-width: 1846px;
    border-radius: 10px 10px 0 0;
    position: relative;
    z-index: 4;
    width: 100%;
    height: 620px;
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    margin: 0 auto 20px;

    .track-hero__slide {
      height: 100%;
      width: 100%;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: flex-end;

      &::before {
        border-radius: 10px 10px 0 0;
        content: '';
        background: linear-gradient(
          rgba(var(--marbles-background-rgb), 0),
          rgb(var(--marbles-background-rgb))
        );
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 3;
        bottom: 0;
      }
    }

    .track-hero__background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      bottom: 0;
      background-size: cover;
      background-position: center top;
      height: 620px;
      animation: reveal-opacity 2s ease-in-out;

      video {
        position: absolute;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;

        @media screen and (max-width: 768px) and (pointer: coarse) {
          filter: saturate(0.5);
        }
      }
    }

    .track-hero__content {
      position: relative;
      z-index: 5;
      width: 100%;
      max-width: 920px;
      padding-left: 150px;
      bottom: 300px;
      box-sizing: border-box;
      align-items: center;

      display: flex;
      gap: 40px;

      @media screen and (max-width: 720px) and (pointer: coarse) {
        padding: 0 20px;
        gap: 20px;
        bottom: 280px;
      }
    }
    .title {
      font-size: 32px;
      margin-bottom: 10px;
      font-weight: 600;
      text-transform: uppercase;
      font-family: var(--font-formula-stack);
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

      background: linear-gradient(
        90deg,
        rgba(255, 234, 153, 1) 10%,
        rgba(var(--yellow), 1) 50%,
        rgba(255, 234, 153, 1) 60%
      );

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      color: rgba(var(--yellow), 1);
      -webkit-text-fill-color: transparent;
      animation:
        reveal-opacity 2s ease-in-out,
        heading-fancy-color 3s linear infinite;

      @media screen and (max-width: 720px) and (pointer: coarse) {
        font-size: 24px;
      }
    }
    @keyframes heading-fancy-color {
      to {
        background-position: 200% center;
      }
    }

    .description {
      font-size: 20px;
      line-height: 1.4;
      max-width: 440px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
      animation: reveal-opacity 2s ease-in-out;

      @media screen and (max-width: 720px) and (pointer: coarse) {
        font-size: 16px;
      }
    }

    .button {
      margin-top: 30px;
      width: 100%;
      max-width: 130px;
      border-radius: 6px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      height: 46px;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
      animation: reveal-opacity 2s ease-in-out;
      background: rgba(var(--dark-orange), 1);
      transition: all 250ms ease-in-out;
      font-family: var(--font-formula-stack);
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      flex-shrink: 0;
      white-space: nowrap;
      align-items: center;
      gap: 10px;

      &:active {
        transform: scale(0.95);
      }

      &:hover {
        background: rgba(var(--yellow), 1);

        transform: scale(1.05);
      }

      span {
        position: relative;
        z-index: 3;
      }
    }
  }

  .track_inner-content {
    width: 100%;
    max-width: 1846px;
    margin: 0 auto;
    padding-top: 120px;
    padding-left: 60px;
    padding-right: 60px;
    box-sizing: border-box;
    z-index: 9;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    aspect-ratio: 16 / 9;
    justify-content: center;

    @media screen and (max-width: 720px) and (pointer: coarse) {
      justify-content: flex-start;
      padding: 0 20px 30px;
      top: 0;
    }
  }
}

.show-games-title {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  max-width: 1846px;
  margin: 20px 0 10px;
  animation: reveal 1.2s ease-in-out;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    user-select: none;
    color: rgb(var(--yellow));

    svg {
      width: 44px;
      height: 44px;
      fill: currentColor;
      position: relative;
      z-index: 3;
    }
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    font-family: var(--font-formula-stack);

    position: relative;
    z-index: 3;
    margin: 0;
    padding: 0;
    color: #fff;
  }
}

.marbles-logo {
  background: url(/assets/marbles_logo.png) no-repeat center center;
  width: 190px;
  height: 44px;
  left: 40px;
  position: relative;
  box-sizing: border-box;

  background-size: contain;
  z-index: 9999;
  animation: reveal 1s ease-in-out;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  @media screen and (pointer: fine) {
    &.scrolled {
      opacity: 0;
    }
  }

  &:active {
    transform: scale(0.95);
  }

  @media screen and (max-width: 720px) and (pointer: coarse) {
    width: 142px;
    height: 30px;
    left: 0px;
    top: 6px;
  }
}

.marbles-race_outer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100dvh;

  user-select: none;
}

.marbles-race {
  background: rgb(var(--black-color));
  color: #fff;
  display: flex;
  width: 100%;
  max-height: 100dvh;
  aspect-ratio: 16 / 9;
  //max-width: 1846px;
  margin: 0 auto;
  position: relative;

  .game-top-toolbar {
    position: absolute;
  }

  @media (max-width: 768px) and (pointer: coarse) {
    aspect-ratio: 9 / 19.5; // iPhone
    overflow: hidden; // Due to stream overflow (130% width)
  }

  @keyframes race-border-fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .race-borders {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    z-index: 5;
    transition: all 500ms ease-in-out;
    opacity: 0;
    user-select: none;
    pointer-events: none;

    &.active {
      animation: race-border-fade-out 5s ease-in-out backwards;
    }
    &.exit {
      opacity: 0;
    }

    &.race_scheduled {
      box-shadow:
        inset 0 0 60px rgba(var(--blue), 1),
        inset 0 0 30px rgba(var(--green), 1),
        inset 0 0 15px rgba(var(--green), 1);
    }

    &.race_starting,
    &.race_ready {
      box-shadow:
        inset 0 0 60px rgba(var(--yellow), 1),
        inset 0 0 30px rgba(var(--blue), 1),
        inset 0 0 15px rgba(var(--purple), 1);
    }

    &.race_started {
      box-shadow:
        inset 0 0 60px rgba(var(--tomato), 1),
        inset 0 0 30px rgba(var(--blue), 1),
        inset 0 0 15px rgba(var(--purple), 1);
    }

    &.race_ended {
      box-shadow:
        inset 0 0 60px rgba(var(--blue), 1),
        inset 0 0 30px rgba(var(--green), 1),
        inset 0 0 15px rgba(var(--green), 1);
    }

    &.race_settled {
      box-shadow:
        inset 0 0 60px rgba(var(--green), 1),
        inset 0 0 30px rgba(var(--blue), 1),
        inset 0 0 15px rgba(var(--purple), 1);

      .border-a,
      .border-b,
      .border-c,
      .border-d {
        position: absolute;
        filter: blur(25px);
      }
      .border-a {
        left: 0;
        top: 20%;
        bottom: 20%;
        width: 30px;
        background: linear-gradient(to right, rgba(var(--blue), 1) 90%, rgba(var(--blue), 0));
      }
      .border-b {
        right: 0;
        top: 20%;
        bottom: 20%;
        width: 30px;
        background: linear-gradient(to right, rgba(var(--blue), 1) 90%, rgba(var(--blue), 0));
      }
      .border-c {
        left: 20%;
        right: 20%;
        top: 0;
        height: 30px;
        background: linear-gradient(to right, rgba(var(--blue), 1) 90%, rgba(var(--blue), 0));
      }
      .border-d {
        left: 20%;
        right: 20%;
        bottom: 0;
        height: 30px;
        background: linear-gradient(to right, rgba(var(--blue), 1) 90%, rgba(var(--blue), 0));
      }
    }
  }
}

.marbles-race__top {
  position: absolute;

  z-index: 9;
  top: 60px;
  display: flex;
  flex-direction: column;

  p {
    padding: 0;
    margin: 0;
  }
}

.marbles-container {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;

  flex: 1;
  width: 100%;

  @media (max-width: 768px) and (pointer: coarse) {
    min-width: auto;
    width: 100%;
    max-width: 100%;

    &.marbles-race__current {
      min-width: auto;
    }
  }

  .marbles-container__inner {
    position: relative;
    box-sizing: border-box;
    z-index: 3;
  }

  .marbles-container__header {
    display: flex;
    position: relative;
    color: #fff;
    z-index: 3;
    font-size: 14px;
    font-weight: 600;
  }
}

.marbles-race__bottom {
  transform-origin: left bottom;

  display: flex;
  // width: 100%;
  // max-width: 1180px;
  left: 50%;
  // transform: translateX(-50%);
  align-items: flex-end;
  position: absolute;
  bottom: 5px;
  z-index: 9;
  gap: 5px;
  box-sizing: border-box;
  padding: 0 5px;
  flex-direction: column;
  width: 570px;
  align-items: center;

  @media (max-width: 768px) and (pointer: coarse) {
    flex-direction: column;
    transform-origin: unset;
    align-items: stretch;
    left: 0;
    width: 100%;
    z-index: 99;
    min-width: auto;
  }
}

.next-race-bet-panel {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .next-race-bet-panel__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    animation: marbles-race__current 500ms ease-in-out;

    backdrop-filter: var(--primary-backdrop-filter);
    -webkit-backdrop-filter: var(--primary-backdrop-filter);
    background: rgba(var(--primary-component-background), 0.6);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

    border-radius: 12px;
    padding: 5px 5px 5px 10px;
    gap: 10px;

    .next-race-bet-actions {
      display: flex;
      gap: 5px;

      .btn {
        font-size: 10px;
        font-weight: 600;
        font-family: var(--font-formula-stack);
        text-transform: uppercase;
      }
    }

    .next-race-bet-label {
      display: flex;
      align-items: center;
      gap: 6px;
      svg {
        width: 16px;
        height: 16px;
        fill: rgba(var(--yellow));
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
      }
      span {
        font-size: 12px;
        font-weight: 600;
        font-family: var(--font-formula-stack);
        animation: text-fancy-color 1.5s linear infinite reverse;
        background: linear-gradient(
          90deg,
          rgb(255, 234, 153) 10%,
          rgba(var(--yellow), 1) 50%,
          rgb(255, 234, 153) 60%
        );

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--green), 1);
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.marbles-container--place-bet {
  z-index: 9;
  .btn-next-race {
    bottom: 0;
    width: 100%;
    z-index: 9999;
    background: rgba(var(--primary-component-background), 0.9);
    backdrop-filter: var(--primary-backdrop-filter);
    -webkit-backdrop-filter: var(--primary-backdrop-filter);
    border-radius: 12px;
    padding: 0 30px;
    min-height: 52px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-formula-stack);
    text-transform: uppercase;
    cursor: pointer;
    transition: all 250ms ease-in-out;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;

    @media screen and (max-width: 400px) and (pointer: coarse) {
      gap: 15px;
      padding: 0 15px;
    }
    svg {
      transition: all 500ms ease-in-out;
      width: 20px;
      height: 20px;
      fill: rgba(var(--yellow));
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));

      &:first-child {
        transform: rotateY(180deg);
      }
    }

    span {
      animation: text-fancy-color 1.5s linear infinite reverse;
      background: linear-gradient(
        90deg,
        rgb(255, 234, 153) 10%,
        rgba(var(--yellow), 1) 50%,
        rgb(255, 234, 153) 60%
      );
      transition: all 500ms ease-in-out;

      background-clip: border-box;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      color: rgba(var(--green), 1);
      -webkit-text-fill-color: transparent;
    }

    &:hover {
      span {
        transform: scale(1.05);
        background: linear-gradient(
          90deg,
          rgba(172, 251, 176, 1) 10%,
          rgba(var(--blue), 1) 50%,
          rgba(172, 251, 176, 1) 60%
        );
        transition: all 500ms ease-in-out;

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--green), 1);
        -webkit-text-fill-color: transparent;
      }

      svg {
        fill: rgba(var(--green));
        &:first-child {
          transform: rotateY(0deg);
        }
        &:last-child {
          transform: rotateY(180deg);
        }
      }
    }
  }
}

.marbles-race__bottom__info {
  display: none;
  @media screen and (max-width: 768px) and (pointer: coarse) {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    user-select: none;
    display: none;

    .show-race-status {
      .race-status-icon {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -150px;
      left: -10px;
      right: -10px;
      height: 200px;
      background: linear-gradient(
        rgba(var(--black-color), 0),
        rgba(var(--black-color), 1) 25%,
        rgba(var(--black-color), 1)
      );
      pointer-events: none;
      user-select: none;
    }
  }
}

@keyframes marbles-race__current {
  from {
    transform: scale(0.85) translateY(10px);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

.marbles-race__current {
  flex: 1.5;
  min-width: 540px;
  animation: marbles-race__current 500ms ease-in-out;
  box-shadow: var(--primary-background-shadow);
  @media (max-width: 1130px) and (pointer: coarse) {
    flex: 1;
  }
}

.button-with-checkmark {
  .icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(var(--black-color), 0.55);
    box-shadow:
      0 0 15px rgba(0, 0, 0, 0.35),
      0 0 2px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
    border-radius: 999em;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 999em 999em 0 0;
      transform: rotate(0deg);
      animation: rotate 5s infinite linear reverse;
      transform-origin: center bottom;
    }
    .icon-inner {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1));
      border-radius: 999em;
    }

    svg {
      width: 10px;
      height: 10px;
      fill: #fff;
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
      opacity: 0;
      transition: all 150ms ease-in-out;
    }
  }

  &.active {
    .icon {
      &:before {
        border-color: rgb(var(--green));
      }
      .icon-inner {
        background: rgb(var(--green));
      }
      svg {
        opacity: 1;
      }
    }
  }
  &:active {
    .icon {
      transform: scale(0.9);

      svg {
        opacity: 1;
      }
    }
  }
  &:hover {
    &.active {
      .icon {
        &:before {
          border-color: rgb(var(--green));
        }
        .icon-inner {
          background: rgb(var(--green));
        }
      }
    }
    .icon {
      &:before {
        border-color: rgb(var(--yellow));
      }
      .icon-inner {
        background: rgb(var(--yellow));
      }
      svg {
        opacity: 1;
      }
    }
  }
}

.place-bet {
  backdrop-filter: var(--primary-backdrop-filter);
  -webkit-backdrop-filter: var(--primary-backdrop-filter);
  background: rgba(var(--primary-component-background), 0.8);
  border-radius: 12px;

  @media screen and (pointer: coarse) {
    display: flex;
    flex-direction: column;
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  .btn-close-bet-on-next-race {
    background: none;
    border: none;
    cursor: pointer;
    background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px 12px 0 0;
    width: 44px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    z-index: 9999;
    transition: all 250ms ease-in-out;

    top: 1px;
    width: 100%;
    // transform: translateX(50%);

    span {
      font-size: 12px;
      font-weight: 600;
      font-family: var(--font-formula-stack);
      text-transform: uppercase;
      color: rgb(var(--yellow));
      transition: all 250ms ease-in-out;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: rgba(255, 255, 255, 0.8);
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
      transition: all 250ms ease-in-out;
      position: absolute;
      right: 10px;
    }

    &:hover {
      background: rgba(var(--primary-component-background), 0.95);

      span {
        color: rgba(255, 255, 255, 0.8);
      }

      svg {
        fill: rgb(var(--yellow));
        transform: rotate(90deg);
      }
    }
  }

  .floating-button-container {
    position: absolute;
    bottom: 0;
    right: -92px;
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    flex-direction: column;
    justify-content: flex-end;

    @media screen and (pointer: coarse) {
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      margin-top: 5px;
      order: 4;
      width: 100%;
      gap: 0;
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      backdrop-filter: var(--primary-backdrop-filter);
      -webkit-backdrop-filter: var(--primary-backdrop-filter);
      background: rgba(var(--primary-component-background), 0.9);
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    }

    .button-collection {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      height: 52px;
      border-radius: 12px;

      @media screen and (pointer: coarse) {
        max-width: 0;
        border-right: 0;
        transition: all 500ms ease-in-out;
        opacity: 0;
        &.visible {
          max-width: 200px;
          border-right: 1px solid rgba(255, 255, 255, 0.15);
          opacity: 1;
        }

        border-radius: 0;
      }
    }

    button {
      box-sizing: border-box;
      width: 82px;
      height: 52px;
      cursor: pointer;
      transition: all 250ms ease-in-out;
      position: relative;
      overflow: hidden;
      flex: 1;
      backdrop-filter: var(--primary-backdrop-filter);
      -webkit-backdrop-filter: var(--primary-backdrop-filter);
      background: rgba(var(--primary-component-background), 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      text-transform: uppercase;
      flex-direction: column;
      border-radius: 12px;
      color: rgba(255, 255, 255, 0.8);
      gap: 4px;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      font-size: 10px;
      font-family: var(--font-formula-stack);
      font-weight: 600;

      &.btn-repeat,
      &.btn-reset {
        opacity: 0;
        visibility: hidden;
        max-height: 0;

        @media screen and (pointer: coarse) {
          max-width: 0;
          transition: none;
          border-right: 0;
        }

        &.visible {
          opacity: 1;
          visibility: visible;
          max-height: 52px;

          @media screen and (pointer: coarse) {
            max-width: 100%;
          }
        }
      }

      &.btn-reset {
        &:hover {
          .icon {
            svg {
              transform: rotate(10deg);
            }
          }
        }
      }

      &.btn-auto-enable {
        &:hover {
          .icon {
            svg {
              transform: rotate(360deg);
            }
          }
        }
        &.active {
          color: rgb(var(--green));

          .icon {
            svg {
              fill: rgb(var(--green));
            }
          }
        }
      }

      &.btn-repeat,
      &.advanced-button {
        &:hover {
          .icon {
            svg {
              transform: rotate(360deg);
            }
          }
        }
      }

      &:hover,
      &.active {
        color: rgb(var(--yellow));

        .icon {
          svg {
            fill: rgb(var(--yellow));
          }
        }
      }

      .icon {
        svg {
          width: 18px;
          height: 18px;
          position: relative;
          z-index: 3;
          fill: rgba(255, 255, 255, 0.8);
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
          transition: all 500ms ease-in-out;
        }
      }

      @media screen and (pointer: coarse) {
        border-radius: 0;
        background: none;
        box-shadow: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        flex: 1;
        border-right: 1px solid rgba(255, 255, 255, 0.15);

        width: auto;

        &:last-child {
          border-right: none;
        }

        &.btn-repeat,
        &.btn-reset,
        &.advanced-button,
        &.btn-auto-enable {
          &:hover {
            .icon {
              svg {
                transform: none;
              }
            }
          }
        }

        &:hover {
          color: rgba(255, 255, 255, 0.8);
          .icon {
            svg {
              fill: rgba(255, 255, 255, 0.8);
              transform: none;
            }
          }
        }
        &.active {
          color: rgb(var(--yellow));
          .icon {
            svg {
              fill: rgb(var(--yellow));
            }
          }
        }
      }
    }
  }

  .place-bet__nav {
    transition: all 500ms ease-in-out;
    display: flex;

    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    flex-shrink: 0;
    justify-content: space-between;
    position: relative;
    z-index: 3;

    padding: 0;
    margin: 0;

    max-height: 340px;

    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

    @media screen and (pointer: coarse) {
      order: 2;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
      background: rgba(var(--primary-component-background), 0.9);
    }

    &.show {
      max-height: 52px;
      opacity: 1;
    }

    &.hidden {
      max-height: 0;
      opacity: 0;
    }

    button {
      box-sizing: border-box;
      padding: 0 10px;
      height: 52px;
      cursor: pointer;
      transition: all 250ms ease-in-out;
      position: relative;
      overflow: hidden;
      flex: 1;
      background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
      font-size: 12px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      text-transform: uppercase;
      border-right: 1px solid rgba(255, 255, 255, 0.15);

      z-index: 2;
      font-family: var(--font-formula-stack);
      font-weight: 600;
      user-select: none;

      svg {
        width: 18px;
        height: 18px;
        position: relative;
        z-index: 3;
        fill: rgba(255, 255, 255, 0.4);
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
        transition: all 250ms ease-in-out;
      }
      span {
        transition: all 250ms ease-in-out;
      }

      @media screen and (pointer: coarse) {
        font-size: 10px;

        svg {
          width: 14px;
          height: 14px;
        }
      }

      @media screen and (max-width: 440px) and (pointer: coarse) {
        justify-content: center;
        svg {
          display: none;
        }
      }

      &:last-child {
        border-right: none;
      }

      &:active {
        span,
        svg {
          transform: scale(0.9);
        }
      }
      &:hover {
        color: rgb(var(--yellow));
      }

      &.active {
        background: none;

        span {
          animation: text-fancy-color 1.5s linear infinite reverse;
          background: linear-gradient(
            90deg,
            rgba(172, 251, 176, 1) 10%,
            rgba(var(--blue), 1) 50%,
            rgba(172, 251, 176, 1) 60%
          );

          background-clip: border-box;
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(var(--green), 1);
          -webkit-text-fill-color: transparent;
        }

        svg {
          fill: rgba(var(--green), 1);
        }
      }
    }
  }

  @keyframes choose-marbles-outer-pick-winner {
    0% {
      width: 0;
    }
    50% {
      width: 100%;
      opacity: 1;
    }
    100% {
      width: 100%;
      opacity: 0;
    }
  }
  @keyframes choose-marbles-outer-pick-order {
    0% {
      width: 0;
    }
    50% {
      width: 100%;
      opacity: 1;
    }
    100% {
      width: 100%;

      opacity: 0;
    }
  }

  .info {
    box-sizing: border-box;

    transition: all 500ms ease-in-out;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-formula-stack);
    position: relative;
    display: block;
    user-select: none;
    text-align: center;
    padding: 10px 0;

    //background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));

    @media screen and (max-width: 768px) and (pointer: coarse) {
      font-size: 12px;
    }

    strong {
      font-weight: 600;
      color: rgb(var(--yellow));
    }
  }

  .choose-multiple__outer {
    .chosen-probability {
      font-size: 12px;
      margin-top: 10px;
    }
  }

  .bet-details {
    font-weight: 600;
    font-family: var(--font-formula-stack);
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: center;
    height: 52px;
    max-height: 0;
    opacity: 0;
    transition: all 500ms ease-in-out;
    display: flex;
    justify-content: space-evenly;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
    visibility: hidden;
    pointer-events: none;
    padding: 0 10px;
    gap: 6px;

    .entry {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;

      .label {
        font-size: 10px;
        color: rgba(255, 255, 255, 0.75);

        display: flex;
        align-items: center;
        box-sizing: border-box;
        user-select: none;

        text-transform: uppercase;
        text-align: center;
      }

      .value {
        font-size: 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: rgb(var(--green));
        font-variant-numeric: tabular-nums;

        animation: text-fancy-color 1.5s linear infinite reverse;

        background: linear-gradient(
          90deg,
          rgba(172, 251, 176, 1) 10%,
          rgba(var(--green), 1) 50%,
          rgba(172, 251, 176, 1) 60%
        );

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--green), 1);
        -webkit-text-fill-color: transparent;
      }
    }

    &.visible {
      max-height: 52px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  .bet-amount {
    box-sizing: border-box;
    border-radius: 0 0 12px 12px;
    position: relative;
    z-index: 10;
    box-shadow:
      inset 0 1px 0 rgba(255, 255, 255, 0.15),
      inset 0 -1px 0 rgba(255, 255, 255, 0.15);
    display: flex;
    flex-direction: column;

    @media screen and (pointer: coarse) {
      order: 3;
      background: rgba(var(--primary-component-background), 0.9);
    }

    .bet-amount_top {
      display: flex;
      flex: 1;
      width: 100%;
      overflow: hidden;

      .bet-amount-scroll-container {
        display: flex;
        flex: 1;
        .bet-amount-buttons {
          display: flex;
          flex: 1;
          transition: opacity 0.15s ease-out;

          &.swiping {
            opacity: 0.8;

            .btn--amount {
              &:hover:not([disabled]) {
                color: rgba(255, 255, 255, 0.8);
              }
            }
          }
        }
      }

      .btn {
        font-size: 12px;
        font-weight: 600;
        font-family: var(--font-formula-stack);
        padding: 10px 0;
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        position: relative;
        overflow: hidden;
        transition: all 250ms ease-in-out;
        border-right: 1px solid rgba(255, 255, 255, 0.1);
        min-height: 52px;
        min-width: 58px;
        flex: 1;
        background: linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.05));

        &.btn--clear {
          font-size: 12px;
          max-width: 58px;

          &:hover {
            svg {
              transform: rotate(-10deg);
            }
          }
        }

        &.btn--amount {
          background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));

          span {
            animation: btn-amount-animation 250ms ease-out;
          }

          @keyframes btn-amount-animation {
            0% {
              opacity: 0;
              transform: scale(0.6) translateX(-10px);
            }
            50% {
              opacity: 0.5;
              transform: scale(1.1) translateX(-5px);
            }
            100% {
              opacity: 1;
              transform: scale(1) translateX(0);
            }
          }

          &:hover:not([disabled]) {
            color: rgb(var(--green));
            box-shadow: inset 0 1px 0 rgba(var(--green), 1);
          }
        }

        &.btn--scroll {
          min-width: 36px;
          max-width: 36px;
          svg {
            width: 18px;
            height: 18px;
          }
        }

        &.btn--double {
          font-size: 12px;
          max-width: 58px;
          text-transform: none;
          border-right: 0;
        }

        svg {
          width: 16px;
          height: 16px;
          fill: currentColor;
          transition: all 250ms ease-in-out;
        }

        span {
          user-select: none;
          transition: all 150ms ease-in-out;
        }

        &:active {
          svg,
          span {
            transform: scale(0.85);
          }
        }

        &:hover:not([disabled]) {
          color: rgb(var(--yellow));
          box-shadow: inset 0 1px 0 rgba(var(--yellow), 1);
          background: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
        }

        &:disabled {
          color: rgba(255, 255, 255, 0.6);
          background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
        }

        @media screen and (pointer: coarse) {
          &:hover:not([disabled]) {
            box-shadow: none;
            background: linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.05));
            color: rgba(255, 255, 255, 0.8);
          }
          &:active {
            span {
              color: rgb(var(--yellow));
            }
            svg {
              fill: rgb(var(--yellow));
            }
          }
          &.btn--amount {
            &:hover:not([disabled]) {
              box-shadow: none;
              background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
              color: #fff;
            }
            &:active {
              span {
                color: rgba(var(--green), 1);
              }
            }
          }
        }
      }
    }
  }

  @keyframes gradient-move {
    0% {
      background-position: 0% 92%;
    }
    50% {
      background-position: 100% 9%;
    }
    100% {
      background-position: 0% 92%;
    }
  }

  .btn-place-bet {
    border-radius: 0 0 12px 12px;
    box-sizing: border-box;
    flex-shrink: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 16px;
    text-transform: uppercase;
    padding: 0 15px;
    min-width: 150px;
    text-decoration: none;
    position: relative;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    font-variant-numeric: tabular-nums;
    animation: gradient-move 3s ease infinite reverse;

    background-size: 200% auto;
    background: linear-gradient(
        225deg,
        rgba(var(--green), 0.8),
        rgba(var(--dark-green), 1),
        rgba(var(--green), 0.8)
      )
      0% 0% / 300% 300%;
    transition: all 250ms ease-in-out;
    font-family: var(--font-formula-stack);

    font-variant-numeric: tabular-nums;
    font-weight: 600;
    color: #fff;
    flex-shrink: 0;
    min-height: 52px;
    white-space: nowrap;
    align-items: center;
    border-left: 0 solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    gap: 10px;

    // fancy shadow
    box-shadow:
      inset 0 1px 0 rgba(255, 255, 255, 0.25),
      inset 0 -1px 0 rgba(255, 255, 255, 0.1),
      inset 0 10px 20px rgba(255, 255, 255, 0.15),
      inset 0 -10px 20px rgba(255, 255, 255, 0.25),
      inset -10px 0 20px rgba(255, 255, 255, 0.15),
      inset 10px 0 10px rgba(255, 255, 255, 0.15);

    .progress-bar {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.2);
      transform-origin: left;
      z-index: 0;

      box-shadow:
        inset 0 1px 0 rgba(255, 255, 255, 0.25),
        inset 0 -1px 0 rgba(255, 255, 255, 0.15),
        inset 1px 0 0 rgba(255, 255, 255, 0.1);
      background: linear-gradient(90deg, rgba(var(--tomato), 1), rgba(var(--tomato), 1));
    }

    @keyframes progress-fill {
      from {
        transform: scaleX(var(--initial-progress, 0));
      }
      to {
        transform: scaleX(1);
      }
    }
    .btn-bet__inner {
      display: flex;
      gap: 10px;
      transition: all 0.25s ease-in-out;

      .btn-bet-amount {
        transition: all 0.25s ease-in-out;
        transform: scale(1);

        &.changed {
          transform: scale(1.15);
        }
      }
    }
    &:hover:not(.auto-active),
    &:hover:not([disabled]) {
      background: linear-gradient(
          225deg,
          rgba(var(--green), 0.6),
          rgba(var(--dark-green), 0.8),
          rgba(var(--green), 0.6)
        )
        0% 0% / 300% 300%;
    }
    @media screen and (pointer: fine) {
      &[disabled][data-tooltip-text]:not(.loading) {
        svg,
        span {
          transition: none;
        }

        &::after {
          content: attr(data-tooltip-text);
          user-select: none;
          pointer-events: none;
          position: absolute;
          left: 0;
          right: 0;
          color: #fff;
          font-size: 14px;
          opacity: 0;
        }
        &:hover {
          svg,
          span {
            opacity: 0;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }

    svg {
      width: 18px;
      height: 18px;
      fill: currentColor;
      position: relative;
      z-index: 3;
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
      transition: all 250ms ease-in-out;
    }

    .auto-countdown {
      color: #fff;
      font-weight: bold;
    }

    &.auto-active {
      background: linear-gradient(
          225deg,
          rgba(var(--yellow), 1),
          rgba(var(--orange), 1),
          rgba(var(--orange), 1)
        )
        0% 0% / 300% 300%;
      background-size: 200% auto;

      svg {
        animation: place-bet-btn-spin 3s linear infinite;
      }
    }

    span {
      position: relative;
      z-index: 3;
      transition: all 250ms ease-in-out;
    }

    &:disabled {
      box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.1);
      animation: gradient-move 3s ease infinite reverse;

      background-size: 200% auto;
      background: linear-gradient(
          225deg,
          rgba(var(--green), 0.8),
          rgba(var(--dark-green), 1),
          rgba(var(--green), 0.8)
        )
        0% 0% / 300% 300%;

      color: #fff;
      animation: none;
      box-shadow: none;

      &:hover {
        animation: gradient-move 3s ease infinite reverse;
      }
    }

    .loading-spinner {
      border: 4px solid rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      border-top: 4px solid rgba(255, 255, 255, 1);
      width: 18px;
      height: 18px;
      opacity: 0;
      max-width: 0;
      max-height: 0;
    }

    @keyframes place-bet-btn-spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    //make it spin
    &.loading {
      &[data-tooltip-text] {
        &::after,
        &::before {
          opacity: 0;
          visibility: hidden;
        }
      }
      .loading-spinner {
        opacity: 1;
        animation: place-bet-btn-spin 1s linear infinite;
        max-width: 18px;
        max-height: 18px;
      }

      .auto-countdown {
        opacity: 0;
        max-width: 0;
        max-height: 0;
      }
    }
  }
}

.choose-marbles-order {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 5px;

  &.forecast {
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 360px) and (pointer: coarse) {
      grid-template-columns: 1fr;
    }
  }

  &.tricast {
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (max-width: 490px) and (pointer: coarse) {
      grid-template-columns: 1fr;
    }
  }

  &.show-single-position-compact {
    display: flex;
    .entry-order {
      .entry-marble {
        width: 30px;
        height: 30px;
        .marble {
          width: 20px;
          height: 20px;
          font-size: 11px;
        }
      }
      .entry-selector {
        min-width: 34px;
        .select {
          width: 20px;
          height: 30px;
          background: rgba(255, 255, 255, 0.1);
          font-size: 9px;

          svg {
            width: 18px;
            height: 18px;
            fill: currentColor;

            animation: rotate-180 8s ease-in-out infinite;
          }
          &:last-child {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }

  @keyframes rotate-180 {
    0% {
      transform: rotate(0deg);
    }
    16.67% {
      transform: rotate(180deg);
    }
    50% {
      transform: rotate(180deg);
    }
    66.67% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .entry-order {
    display: flex;
    border-radius: 24px 12px 12px 24px;

    background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
    flex: 1;
    position: relative;

    &.entry-order-marble-spin {
      .entry-marble {
        .marble {
          img {
            animation: spin-simple 8s linear infinite;
          }
        }
      }
    }

    .entry-marble {
      flex-shrink: 0;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
      border-radius: 24px 0 0 24px;
      position: relative;

      @media screen and (max-width: 490px) and (pointer: coarse) {
        width: 40px;
        height: 40px;
      }

      .marble {
        width: 34px;
        height: 34px;

        @media screen and (max-width: 490px) and (pointer: coarse) {
          width: 24px;
          height: 24px;
          font-size: 11px;
        }

        span {
          img {
            animation: spin-simple 8s linear infinite reverse;
          }
        }
      }
    }
    .entry-selector {
      display: flex;
      flex: 1;

      @media screen and (max-width: 490px) and (pointer: coarse) {
        min-width: 34px;
      }

      &.has-selection {
        .select {
          .select-label {
            animation: none;
          }
        }
      }
      .select {
        font-size: 10px;
        font-weight: 600;
        font-family: var(--font-formula-stack);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 44px;
        flex-direction: column;
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        cursor: pointer;
        color: rgba(255, 255, 255, 0.6);
        transition: all 150ms ease-in-out;
        user-select: none;
        flex: 1;
        text-transform: uppercase;

        @media screen and (max-width: 490px) and (pointer: coarse) {
          width: 20px;
          height: 40px;
          background: rgba(255, 255, 255, 0.1);
          font-size: 9px;
          border-right: 1px solid rgba(0, 0, 0, 0.2);

          &:last-child {
            border-radius: 0 8px 8px 0;
            border-right: none;
          }
        }

        &:hover {
          color: rgba(255, 255, 255, 1);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.6);
        }
        &:active {
          color: rgba(255, 255, 255, 0.6);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        }
        &:last-child {
          border-radius: 0 12px 12px 0;
        }

        &.st {
          &.selected {
            box-shadow: inset 0 1px 0 rgb(var(--green));
            background: linear-gradient(rgba(var(--green), 0.2), rgba(var(--green), 0.1));
            color: rgb(var(--green));
          }
        }

        &.nd {
          background: rgba(255, 255, 255, 0.1);

          &.selected {
            box-shadow: inset 0 1px 0 rgb(var(--yellow));
            background: linear-gradient(rgba(var(--yellow), 0.2), rgba(var(--yellow), 0.1));
            color: rgb(var(--yellow));
          }
        }

        &.rd {
          &.selected {
            box-shadow: inset 0 1px 0 rgb(var(--blue));
            background: linear-gradient(rgba(var(--blue), 0.2), rgba(var(--blue), 0.1));
            color: rgb(var(--blue));
          }
        }
      }
    }
  }
}

.low-balance {
  font-weight: bold;
  color: rgb(255, 255, 0);
}

@keyframes spin-simple {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.3);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

// PREVIEW PAGE TEMP
@keyframes reveal {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes reveal-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes reveal-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes right-floating-panel-slide-in {
  0% {
    right: -80px;
  }
  100% {
    right: 10px;
  }
}
@keyframes left-floating-panel-slide-in {
  0% {
    left: -80px;
  }
  100% {
    left: 10px;
  }
}

// bottom-floating-panel (Below stream)
.bottom-floating-panel {
  display: none;
  @media screen and (max-width: 768px) and (pointer: coarse) {
    display: flex;
    flex-direction: row;
    bottom: -35px;
    left: 15px;
    right: 15px;
    align-items: flex-end;
    gap: 10px;
    position: absolute;
    z-index: 20;
    transition: all 500ms ease-in-out;

    .show-race-status {
      position: relative;
      bottom: unset;
      right: unset;
      left: unset;
      transform: none;
      order: 3;
    }

    &.race_started.user-has-bet {
      .show-past-winners {
        .marbles {
          opacity: 0;
        }
      }
    }
  }
}

// right-floating-panel
.right-floating-panel {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 99;
  gap: 8px;
  display: flex;
  flex-direction: column;
  transform-origin: right top;
  animation: right-floating-panel-slide-in 1.2s ease-in-out;
  align-items: flex-end;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    display: none;
  }

  @media screen and (max-width: 768px) and (pointer: coarse) {
    .show-race-status {
      display: none;
    }
  }
}

// show-race-status
.show-race-status {
  display: flex;
  gap: 10px;
  align-items: center;
  z-index: 5;
  position: relative;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }

  $event-colors: (
    'race_scheduled': var(--blue),
    'race_started': var(--tomato),
    'race_ended': var(--blue),
    'race_settled': var(--green),
    'race_cancelled': var(--red),
    'race_starting': var(--yellow),
    'race_ready': var(--yellow)
  );

  @function create-gradient($color) {
    $light-color: rgba(255, 255, 255, 0.8);
    @if $color == var(--blue) {
      $light-color: rgba(163, 220, 234, 1);
    } @else if $color == var(--tomato) {
      $light-color: rgba(253, 163, 147, 1);
    } @else if $color == var(--purple) {
      $light-color: rgba(241, 167, 255, 1);
    } @else if $color == var(--green) {
      $light-color: rgba(172, 251, 176, 1);
    } @else if $color == var(--yellow) {
      $light-color: rgba(255, 234, 153, 1);
    } @else if $color == var(--red) {
      $light-color: rgba(253, 163, 147, 1);
    }

    @return linear-gradient(
      90deg,
      #{$light-color} 10%,
      rgba(#{$color}, 1) 50%,
      #{$light-color} 60%
    );
  }

  &.race_starting {
    .status-countdown {
      &.pulse-animation {
        .status-countdown-number {
          color: rgba(var(--yellow), 1);
        }
      }
    }
  }

  &.race_ended {
    .race-status-icon {
      .status-countdown {
        .icon {
          svg {
            animation: race-ended-icon-animation 1.5s linear infinite;
          }
        }
      }
    }
  }

  &.race_settled {
    .status-countdown {
      &.pulse-animation {
        .status-countdown-number {
          color: rgba(var(--green), 1);
        }
      }
    }
  }

  @each $event, $color in $event-colors {
    &.#{$event} {
      .race-status-message {
        box-shadow:
          inset 0 1px 0 rgba(#{$color}, 0.45),
          inset 0 -3px 12px rgba(0, 0, 0, 0.1),
          inset 0 0 30px rgba(#{$color}, 0.1);

        span {
          background: create-gradient($color);
          background-clip: text;
          -webkit-background-clip: text;
          background-size: 200% auto;
          color: rgba(#{$color}, 1);
          -webkit-text-fill-color: transparent;
        }
      }

      .icon {
        svg {
          fill: rgba(#{$color}, 1);
        }
      }

      svg circle {
        stroke: rgba(#{$color}, 1);
      }

      &:hover {
        .status-countdown {
          &-number {
            color: rgba(#{$color}, 1);
          }
        }
      }
    }
  }

  &:hover {
    .race-status-message {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;

      @media screen and (max-width: 768px) and (pointer: coarse) {
        //  transform: translate(-50%, -10px);
      }
    }
  }

  .race-status-message {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    font-family: var(--font-formula-stack);
    padding: 10px;
    overflow: hidden;
    border-radius: 24px 8px 8px 24px;
    backdrop-filter: var(--primary-backdrop-filter-light);
    -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
    background: rgba(var(--primary-component-background), 0.6);
    box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.15);
    z-index: 3;
    position: absolute;
    white-space: nowrap;
    right: 47px;
    transform: translateX(100%);
    opacity: 0;
    transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.075);
    visibility: hidden;
    pointer-events: none;
    animation: actions-pane-buttons-slide-in 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    @media screen and (max-width: 768px) and (pointer: coarse) {
      bottom: 50px;
      right: 0;
      border-radius: 24px 8px 8px 24px;
    }

    &.show {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;

      // @media screen and (max-width: 768px) and (pointer: coarse) {
      //   transform: translate(-50%, -10px);
      // }
    }

    span {
      animation: text-fancy-color 1.5s linear infinite reverse;
      background-clip: text;
      -webkit-background-clip: text;
      background-size: 200% auto;
      user-select: none;
    }
  }

  .race-status-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2px;
    font-size: 10px;
    font-family: var(--font-formula-stack);
    font-weight: 600;
    cursor: pointer;
    border-radius: 28px;
    width: 42px;
    height: 42px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
    position: relative;
    overflow: hidden;
    backdrop-filter: var(--primary-backdrop-filter-light);
    -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
    background: rgba(var(--primary-component-background), 0.6);
    z-index: 4;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      animation: actions-pane-buttons-slide-up 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: none;
      background: none;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
    }

    span {
      position: relative;
      z-index: 3;
    }
  }

  @keyframes race-ended-icon-animation {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }

    75% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes pulse-countdown {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  .status-countdown {
    position: relative;
    margin: auto;
    height: 40px;
    width: 40px;
    text-align: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      svg {
        width: 16px;
        height: 16px;
        transform: none;
        position: relative;
      }
    }

    &.pulse-animation {
      animation: pulse-countdown 0.5s ease-in-out infinite;

      &-number {
        animation: pulse-countdown 0.5s ease-in-out infinite;
      }
    }

    &-number {
      color: #fff;
      display: inline-block;
      line-height: 40px;
      transition: color 0.3s ease;
      font-variant-numeric: tabular-nums;
    }
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);

    circle {
      stroke-dasharray: 113px;
      stroke-dashoffset: 0px;
      stroke-linecap: round;
      stroke-width: 2px;
      fill: none;
      transition: stroke 0.3s ease;
    }
  }
}
// show-viewer-count
.show-viewer-count {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px;
  font-size: 10px;
  font-family: var(--font-formula-stack);
  font-weight: 600;
  color: rgba(255, 255, 255, 0.65);

  border-radius: 28px;
  width: 42px;
  height: 42px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  position: relative;
  overflow: hidden;

  backdrop-filter: var(--primary-backdrop-filter-light);
  -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
  background: rgba(var(--primary-component-background), 0.6);

  @media screen and (max-width: 768px) and (pointer: coarse) {
    order: 2;
    gap: 8px;
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    animation: actions-pane-buttons-slide-in 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }

  span {
    position: relative;
    z-index: 3;
    color: #fff;
  }
  svg {
    position: relative;
    z-index: 3;
    width: 14px;
    height: 14px;
    fill: rgba(255, 255, 255, 0.75);
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25));
  }
}

@keyframes actions-pane-buttons-slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes actions-pane-buttons-slide-up {
  0% {
    transform: translate(0, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes actions-pane-buttons-slide-up-centered {
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, -10px);
  }
}

// actions-pane-buttons
.actions-pane-buttons {
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    order: 3;
    animation: actions-pane-buttons-slide-in 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
  }

  button {
    min-width: 42px;
    min-height: 42px;
    border-radius: 28px;
    padding: 0;
    flex-direction: column;
    transition: all 500ms ease-in-out;
    backdrop-filter: var(--primary-backdrop-filter-light);
    -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
    background: rgba(var(--primary-component-background), 0.6);
    gap: 0;

    &:before {
      display: none;
    }

    @media screen and (min-width: 768px) {
      &:hover {
        svg {
          transform: rotate(360deg);
        }
      }
    }

    svg {
      margin-bottom: 0;
      transition: all 500ms ease-in-out;
      perspective: 1000px;
      transform-style: preserve-3d;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 0;
      max-width: 42px;
      opacity: 0;

      white-space: nowrap;
      transition: all 500ms ease-in-out;
    }
  }
}

@keyframes show-past-winners-outer-slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

// Past winners
.show-past-winners-outer {
  @media screen and (max-width: 768px) and (pointer: coarse) {
    flex: 1;
    display: flex;
    position: relative;
    animation: show-past-winners-outer-slide-in 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.075);
  }
}

.show-past-winners {
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0 0 8px;
  gap: 0px;
  box-sizing: border-box;

  overflow: hidden;
  flex-direction: column;

  backdrop-filter: var(--primary-backdrop-filter-light);
  -webkit-backdrop-filter: var(--primary-backdrop-filter-light);
  background: rgba(var(--primary-component-background), 0.6);

  transition: all 500ms ease-in-out;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    flex-direction: row;
    flex: unset;
    padding: 0;
    gap: 0;
    order: 1;
    background: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border: 0;
    box-shadow: none;
    overflow: visible;
  }
  @media screen and (min-width: 768px) {
    &.active,
    &:hover {
      border-radius: 10px;
      overflow: unset;
      padding: 4px 4px 8px;
      gap: 8px;

      .marble-label-container {
        gap: 8px;
        opacity: 1;
        max-height: 20px;
        width: 80px;
        margin-top: 0;
        opacity: 1;
        visibility: visible;
      }
      button {
        border-radius: 10px;
        min-width: 100%;
        min-height: 56px;
        background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

        svg {
          margin-bottom: 12px;
          transform: rotate(-10deg);
        }
        span {
          height: 42px;
          width: 80px;
          opacity: 1;
        }
      }

      .marbles {
        .marble-wrapper {
          gap: 4px;

          &:first-child {
            > .marble {
              width: 20px;
              height: 20px;
              border: 0px solid rgba(var(--yellow), 0);
            }
          }

          .marble {
            img {
              transition: transform 250ms ease-in-out;
            }
            &:hover {
              color: #fff;

              img {
                transform: scale(1.2);
              }
            }
          }

          .additional-winners {
            gap: 4px;
            width: 48px;
            opacity: 1;
          }
        }
      }
    }
  }
  // also when not hovered and not active
  @media screen and (min-width: 768px) {
    &:not(:hover):not(.active) {
      .marbles {
        .marble-wrapper {
          &:first-child {
            > .marble {
              width: 30px;
              height: 30px;
              box-sizing: border-box;
              border: 1px solid rgba(var(--green), 0.8);
              background: rgba(var(--marbles-dark-blue), 0.6);
              display: flex;
              align-items: center;
              justify-content: center;

              @media screen and (max-width: 768px) and (pointer: coarse) {
                width: 24px;
                height: 24px;
              }

              img {
                width: 24px;
                height: 24px;
                top: 2px;
                left: 2px;
                transition: all 500ms ease-in-out;

                @media screen and (max-width: 768px) and (pointer: coarse) {
                  width: 20px;
                  height: 20px;
                  left: 1px;
                  top: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
  .marble-label-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    opacity: 0;
    max-height: 0;
    width: 0;
    font-size: 10px;
    font-family: var(--font-formula-stack);
    font-weight: 600;
    color: rgba(255, 255, 255, 0.65);
    transition: all 500ms ease-in-out;
    opacity: 0;
    visibility: hidden;

    > div {
      text-align: center;
      animation: text-fancy-color 1.5s linear infinite reverse;
      transition: all 500ms ease-in-out;

      &.green {
        background: linear-gradient(
          90deg,
          rgba(172, 251, 176, 1) 10%,
          rgba(var(--green), 1) 50%,
          rgba(172, 251, 176, 1) 60%
        );

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--green), 1);
        -webkit-text-fill-color: transparent;
      }

      &.blue {
        background: linear-gradient(
          90deg,
          rgba(163, 220, 234, 1) 10%,
          rgba(var(--blue), 1) 50%,
          rgba(163, 220, 234, 1) 60%
        );

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--blue), 1);
        -webkit-text-fill-color: transparent;
      }

      &.yellow {
        background: linear-gradient(
          90deg,
          rgba(255, 234, 153, 1) 10%,
          rgba(var(--yellow), 1) 50%,
          rgba(255, 234, 153, 1) 60%
        );

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--yellow), 1);
        -webkit-text-fill-color: transparent;
      }
    }
  }

  button {
    min-width: 42px;
    min-height: 42px;
    border-radius: 28px;
    padding: 0;
    flex-direction: column;
    transition: all 250ms ease-in-out;
    gap: 0;
    background: rgba(var(--primary-component-background), 0);

    @media screen and (max-width: 768px) and (pointer: coarse) {
      order: 1;
      margin-right: 8px;
      flex-direction: row;
      padding: 0 10px;
      background: rgba(var(--primary-component-background), 0.6);
    }

    &:before {
      display: none;
    }

    svg {
      margin-bottom: 0;
      transition: all 500ms ease-in-out;
      perspective: 1000px;
      transform-style: preserve-3d;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 0;
      max-width: 42px;
      opacity: 0;

      white-space: nowrap;
      transition: all 500ms ease-in-out;

      @media screen and (max-width: 768px) and (pointer: coarse) {
        max-width: 0;
      }
    }
  }

  .marbles {
    display: flex;
    gap: 8px;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 500ms ease-in-out;
    z-index: 9;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      flex-direction: row;
      order: 2;
      gap: 0;
    }

    .marble-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0;
      transition: all 500ms ease-in-out;

      @media screen and (max-width: 768px) and (pointer: coarse) {
        flex-direction: column;
        width: 20px;
        margin-left: 8px;
        &:first-child {
          margin-left: 0;

          > .marble {
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            border: 1px solid rgba(var(--green), 0.8);
            background: rgba(var(--marbles-dark-blue), 0.6);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
              height: 20px;
              left: 1px;
              top: 1px;
              transition: all 500ms ease-in-out;
            }
          }
        }
        &:nth-child(2) {
          opacity: 0.8;
        }
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          opacity: 0.6;
        }
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          opacity: 0;
          max-width: 0;
          margin-left: 0;
          pointer-events: none;
        }
      }

      @media screen and (max-height: 560px) {
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          display: none;
        }
      }

      @media screen and (max-height: 560px) {
        &:nth-child(6),
        &:nth-child(5) {
          display: none;
        }
      }

      .marble {
        &::before {
          z-index: 3;
          pointer-events: none;
        }
      }

      .additional-winners {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0;
        gap: 0;
        opacity: 0;
        margin-left: 0;
        transition: all 500ms ease-in-out;

        @media screen and (max-width: 768px) and (pointer: coarse) {
          flex-direction: column;
          max-height: 0;
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      font-size: 10px;
      font-family: var(--font-formula-stack);
      font-weight: 600;
    }
  }
}

// marbles-bet-container
.marbles-bet-container {
  width: 100%;
  animation: marbles-race__current 500ms ease-in-out;

  .std-table {
    overflow: unset;
  }

  @media screen and (max-width: 768px) and (pointer: coarse) {
    max-width: 100%;

    .std-table {
      overflow: hidden;
    }
  }
}

// std table

.std-table {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;

  &.four-rows {
    .std-table__inner-body {
      .entry {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
    .std-table__inner-header {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &.three-rows-multiple-columns {
    .std-table__inner-body {
      .entry {
        padding: 0;
      }
    }
    .std-table__inner-header {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &.one-rows-multiple-columns {
    .std-table__inner-body {
      .entry {
        padding: 0;

        .top-bar {
          padding: 10px 10px 0;
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    .std-table__inner-header {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.two-rows-multiple-columns {
    .std-table__inner-body {
      .entry {
        padding: 0;

        .top-bar {
          padding: 10px 10px 0;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    .std-table__inner-header {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.three-rows {
    .std-table__inner-body {
      .entry {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .std-table__inner-header {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

.top-bar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px 10px 0;
  box-sizing: border-box;
  align-items: center;

  .top-bar_entry-row {
    display: flex;
    flex-direction: column;

    .label {
      font-size: 10px;
      color: rgba(255, 255, 255, 0.75);
    }
  }

  .time {
    text-align: right;
  }
  .race-id {
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 10px;

    svg {
      width: 12px;
      height: 12px;
      fill: rgba(255, 255, 255, 0.65);
      filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
    }
  }

  .time-ago {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.75);
  }

  > div {
    position: relative;
    z-index: 3;
  }
}

.expanded-info-bar {
  display: flex;
  flex-direction: column;
  gap: 5px;
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  z-index: 3;

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  opacity: 0;

  &.visible {
    max-height: 300px;
    opacity: 1;
    padding: 10px 10px 0;
    position: relative;
  }

  .info-entry {
    display: grid;
    grid-template-columns: 1fr 2fr 54px;
    gap: 5px;
    justify-content: space-between;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.75);
    align-items: center;

    button {
      flex: 0;
      padding: 0 25px;
      box-sizing: border-box;
      white-space: nowrap;
    }

    .label {
      font-weight: 600;
    }

    .value {
      white-space: wrap;
    }
  }
}

.bottom-bar {
  border-radius: 10px;
  margin: 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  z-index: 6;
  padding: 6px 6px;
  box-sizing: border-box;
  position: relative;
  background: rgba(var(--primary-dark-color), 0.5);

  &.bottom-bar--past-races {
    .marbles {
      .marble {
        img {
          transition: transform 250ms ease-in-out;
        }
        &:hover {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  &.bottom-bar__current-bet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

    .bottom-bar__current-bet__label {
      font-size: 12px;
      font-weight: 600;
      color: rgba(255, 255, 255, 0.65);
      text-transform: uppercase;
    }

    .bottom-bar__current-bet__content {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      gap: 10px;

      .label {
        white-space: nowrap;

        @media (max-width: 420px) and (pointer: coarse) {
          flex: 1;
        }
      }
    }
  }

  &.past-winners {
    margin-top: 5px;
    justify-content: center;
    overflow: hidden;

    @media (max-width: 1160px) and (pointer: coarse) {
      .marble:nth-child(9),
      .marble:last-child {
        display: none;
      }
    }
  }

  .label {
    font-size: 12px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.65);
    text-transform: uppercase;
    margin-right: 10px;
  }

  .marbles {
    display: flex;
    gap: 5px;
    position: relative;
    z-index: 4;

    span {
      width: 100%;
      text-align: center;
      position: absolute;
      top: 0;
      bottom: 0;
    }
  }
}
.std-table__inner {
  position: relative;
  box-sizing: border-box;
  z-index: 3;

  display: grid;

  &.mobile-reverse {
    @media screen and (max-width: 768px) and (pointer: coarse) {
      display: flex;
      flex-direction: column-reverse;
      gap: 5px;

      .std-table__inner-body {
        margin-top: 0;
      }
    }
  }

  // grid style table
  .std-table__inner-header {
    display: grid;
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    margin: 10px 10px 0;
  }

  .std-table__inner-body {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    padding: 6px 5px;
    box-sizing: border-box;
    border-radius: 12px;
    gap: 5px;
    max-height: 159px;
    // overflow: scroll;
    flex-shrink: 0;

    &.no-max-height {
      max-height: 100%;
    }

    @media (max-width: 680px) and (pointer: coarse) {
      max-height: 206px;

      &.no-max-height {
        max-height: 100%;
      }
    }

    @keyframes current-bet-lost-animation {
      0% {
        transform: scale(1);
      }
      33% {
        transform: scale(0.98) rotate(1deg);
      }
      50% {
        transform: scale(0.98) rotate(-1deg);
      }
      66% {
        transform: scale(0.98) rotate(1deg);
      }
      100% {
        transform: scale(1) rotate(0deg);
      }
    }

    @keyframes current-bet-won-animation {
      0% {
        transform: scale(1);
      }
      33% {
        transform: scale(0.98);
      }
      50% {
        transform: scale(1.02);
      }
      66% {
        transform: scale(0.98);
      }
      100% {
        transform: scale(1);
      }
    }

    &.current-bet-pane {
      padding: 1px 0 0;
      box-shadow: var(--primary-background-shadow);
      backdrop-filter: var(--primary-backdrop-filter);
      -webkit-backdrop-filter: var(--primary-backdrop-filter);
      background: rgba(var(--primary-component-background), 0.8);
      border-radius: 12px;

      .entry {
        flex-direction: row-reverse;
        border-radius: 12px;

        &.lost,
        &.won {
          transform-style: preserve-3d;
          transform-origin: center;
          perspective: 1000px;
        }

        &.lost {
          animation: current-bet-lost-animation 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

          @keyframes pick-sad-animation {
            0% {
              transform: rotate(0deg);
            }
            50% {
              transform: rotate(20deg);
            }
          }

          .bottom-bar {
            .PickWinner {
              .marbles {
                .marble {
                  animation: pick-sad-animation 1s infinite cubic-bezier(0.175, 0.885, 0.42, 1.075);
                }
                @for $i from 1 through 8 {
                  .marble:nth-child(#{$i}) {
                    animation-delay: #{$i * 0.1}s;
                  }
                }
              }
            }
          }
        }

        &.won {
          animation: current-bet-won-animation 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);

          @keyframes pick-winnerwave-animation {
            0% {
              transform: translateY(0) rotate(0deg);
            }
            50% {
              transform: translateY(-4px) rotate(-20deg);
            }
          }

          .bottom-bar {
            .PickWinner {
              .marbles {
                .marble {
                  animation: pick-winnerwave-animation 1s infinite
                    cubic-bezier(0.175, 0.885, 0.42, 1.075);
                  opacity: 0.4;

                  &.winning-marble {
                    opacity: 1;
                  }
                }
                @for $i from 1 through 8 {
                  .marble:nth-child(#{$i}) {
                    animation-delay: #{$i * 0.1}s;
                  }
                }
              }
            }
          }
        }

        &::before {
          left: 0;
          right: 0;
          top: 0;
          border-radius: 12px;
        }
        .top-bar {
          grid-template-columns: repeat(1, 1fr);
          justify-items: center;

          .bet-entry {
            width: 100%;
            justify-content: space-between;
            gap: 15px;

            > div:first-child {
              align-items: flex-start;
            }
          }
        }
        .bottom-bar__current-bet {
          padding: 0 5px 0 0;

          @media (max-width: 560px) and (pointer: coarse) {
            gap: 5px;
          }
          .left {
            display: flex;
            gap: 10px;
            padding: 13px 10px;
            border-radius: 8px 0 0 8px;
            justify-content: space-between;

            background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));

            @media (max-width: 560px) and (pointer: coarse) {
              flex-direction: column;
              gap: 0;
              padding: 5px 10px;
            }

            .label {
              animation: text-fancy-color 1.5s linear infinite reverse;
              background: linear-gradient(
                90deg,
                rgba(172, 251, 176, 1) 10%,
                rgba(var(--blue), 1) 50%,
                rgba(172, 251, 176, 1) 60%
              );

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--green), 1);
              -webkit-text-fill-color: transparent;

              &.PickWinner {
                background: linear-gradient(
                  90deg,
                  rgba(255, 234, 153, 1) 10%,
                  rgba(var(--yellow), 1) 50%,
                  rgba(255, 234, 153, 1) 60%
                );

                background-clip: border-box;
                background-clip: text;
                -webkit-background-clip: text;
                background-size: 200% auto;
                color: rgba(var(--yellow), 1);
                -webkit-text-fill-color: transparent;
              }
            }
          }

          .bottom-bar__current-bet__content {
            &.PickWinner {
              .marbles {
                margin: 0 5px;
              }
            }
          }
        }
      }

      @media screen and (min-width: 768px) {
        flex: 1;
        .entry {
          display: flex;
          overflow: unset;
          gap: 0;

          .top-bar {
            padding: 0 15px;
            display: flex;
          }

          .bottom-bar__current-bet {
            margin: 5px 0 5px 5px;
            border-radius: 8px;
            gap: 5px;

            .left {
              gap: 0;
              flex-direction: column;
              padding: 6px 10px;

              .label {
                white-space: nowrap;
                margin: 0;
              }

              .bottom-bar__current-bet__label {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    .entry {
      display: grid;
      align-items: center;
      gap: 10px;
      box-sizing: border-box;
      padding: 10px;

      border-radius: 10px;
      background: linear-gradient(rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.05));
      box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.35);
      font-size: 12px;
      position: relative;
      overflow: hidden;
      flex-shrink: 0;

      &::before {
        content: '';
        position: absolute;
        left: -30px;
        right: -30px;
        bottom: 0;
        top: 0;
        opacity: 0.7;
        z-index: 1;
        border-radius: 12px 12px 0 0;
      }

      &.entry-expandable {
        cursor: pointer;
        &.expanded {
          .expand-icon {
            transform: rotate(0deg);

            svg {
              fill: rgb(var(--green));
            }
          }
          &.lost {
            .expand-icon {
              svg {
                fill: rgb(var(--red));
              }
            }
          }
        }
        .expand-icon {
          position: absolute;
          right: 8px;
          top: 12px;
          z-index: 2;
          transition: transform 150ms ease-in-out;
          transform: rotate(180deg);

          svg {
            width: 16px;
            height: 16px;
            fill: rgba(255, 255, 255, 0.75);
          }
        }
      }

      &.entry--empty {
        padding: 10px;

        span {
          font-size: 10px;
          font-family: var(--font-formula-stack);
          font-weight: 600;
        }
      }

      .tag {
        display: flex;
        align-items: center;
        gap: 5px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        justify-content: center;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--font-formula-stack);
        padding: 5px;
        border-radius: 10px;
        background: linear-gradient(rgba(255, 255, 255, 0.15) 0%, rgba(37, 41, 55, 0.25) 100%);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        color: rgb(var(--yellow));
        text-transform: uppercase;

        svg {
          width: 12px;
          height: 12px;
          fill: currentColor;
        }
      }

      span {
        position: relative;
        z-index: 2;
      }

      .currency {
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
        background: linear-gradient(rgba(255, 255, 255, 0.15) 0%, rgba(37, 41, 55, 0.25) 100%);
        box-sizing: border-box;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: all 150ms ease-in-out;
        margin-right: 10px;
        svg {
          width: 16px;
          height: 16px;
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
          fill: #fff;

          &.usdt {
            fill: #26a17b;
          }
          &.bnb {
            fill: #f3b760;
          }
        }
      }

      &.won {
        box-shadow:
          0 -1px 0 rgba(var(--green), 0.45),
          inset 0 -3px 12px rgba(0, 0, 0, 0.25);
        &::before {
          background: linear-gradient(
              rgba(var(--primary-dark-color), 1) 0%,
              rgba(var(--green), 0) 100%
            ),
            radial-gradient(
              ellipse farthest-side at center top,
              rgba(var(--green), 0.6) 0%,
              rgba(var(--primary-dark-color), 1) 150%,
              rgba(var(--primary-dark-color), 1) 100%
            );
        }
        .amount {
          color: rgb(var(--green));
        }
        .icon {
          color: rgb(var(--green));
        }
        .tag {
          background: none;
          box-shadow: none;
          color: rgb(var(--green));
          z-index: 3;
        }

        .PickWinner {
          .marbles {
            .marble {
              opacity: 0.4;

              &.winning-marble {
                opacity: 1;
              }
            }
          }
        }
      }

      &.has-top-label {
        padding-top: 18px;
      }
      &.pending {
        // padding-top: 18px;
        box-shadow:
          0 -1px 0 rgba(var(--yellow), 0.45),
          inset 0 -3px 12px rgba(0, 0, 0, 0.25),
          inset 0 0 30px rgba(var(--yellow), 0.25);
        .amount {
          color: rgb(var(--yellow));
        }
        .icon {
          color: rgb(var(--yellow));
        }
      }

      &.cancelled {
        opacity: 0.5;

        .amount {
          .amount {
            text-decoration: line-through;
          }
        }

        .tag {
          background: none;
          box-shadow: none;
          color: rgb(var(--white));
          z-index: 3;
        }
      }

      &.past-race {
        .tag {
          color: rgba(255, 255, 255, 0.65);
          z-index: 3;
        }
      }

      &.lost {
        box-shadow:
          0 -1px 0 rgba(var(--tomato), 0.45),
          inset 0 -3px 12px rgba(0, 0, 0, 0.25);
        &::before {
          background: linear-gradient(
              rgba(var(--primary-dark-color), 1) 0%,
              rgba(var(--tomato), 0) 100%
            ),
            radial-gradient(
              ellipse farthest-side at center top,
              rgba(var(--tomato), 0.6) 0%,
              rgba(var(--primary-dark-color), 1) 150%,
              rgba(var(--primary-dark-color), 1) 100%
            );
        }
        .amount {
          color: rgb(var(--red));
        }
        .icon {
          color: rgb(var(--red));
        }
        .tag {
          background: none;
          box-shadow: none;
          color: rgb(var(--red));
          z-index: 3;
        }
        .PickWinner {
          .marbles {
            .marble {
              opacity: 0.4;
            }
          }
        }
      }
      .multiple-currencies {
        display: flex;
        gap: 10px;
      }

      &.cancelled {
        opacity: 0.5;

        .amount {
          .amount {
            text-decoration: line-through;
          }
        }
      }

      .amount {
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      .avatar {
        margin-right: 10px;
      }
      .icon {
        box-shadow:
          inset 0 1px 0 rgba(255, 255, 255, 0.25),
          inset 0 -1px 0 rgba(var(--primary-dark-color), 0.8);
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        transition: all 150ms ease-in-out;
        margin-right: 10px;
        svg {
          width: 16px;
          height: 16px;
          filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
          fill: currentColor;
        }
      }

      .bet-entry {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        &.won {
          .bet-entry__arrow,
          .currency {
            color: rgb(var(--green));
          }
        }
        &.pending {
          .bet-entry__arrow,
          .currency {
            color: rgb(var(--yellow));
          }
        }
        &.lost {
          .bet-entry__label,
          .bet-entry__value,
          .bet-entry__arrow,
          .currency {
            color: rgb(var(--tomato));
          }
          .label--lost {
            font-size: 12px;
          }
        }

        .currency {
          margin-right: 0;
          flex-shrink: 0;
        }

        .bet-entry__arrow {
          svg {
            width: 16px;
            height: 16px;
            fill: currentColor;
            filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
          }
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &.won {
            .bet-entry__value {
              animation: text-fancy-color 3s linear infinite reverse;
              background: linear-gradient(
                90deg,
                rgba(var(--green)) 10%,
                rgba(var(--yellow), 1) 50%,
                rgba(var(--green)) 60%,
                rgba(var(--green)) 75%
              );
              text-shadow: none;

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--yellow), 1);
              -webkit-text-fill-color: transparent;
            }

            .bet-entry__label {
              animation: text-fancy-color 3s linear infinite reverse;
              background: linear-gradient(
                90deg,
                rgb(255, 234, 153) 10%,
                rgba(var(--yellow), 1) 50%,
                rgb(255, 234, 153) 60%
              );
              text-shadow: none;

              background-clip: border-box;
              background-clip: text;
              -webkit-background-clip: text;
              background-size: 200% auto;
              color: rgba(var(--yellow), 1);
              -webkit-text-fill-color: transparent;
            }
          }
          &.lost {
            .bet-entry__value {
              color: rgb(var(--tomato));
            }
            .bet-entry__label {
              color: rgba(var(--tomato), 1);
            }
          }

          .bet-entry__value {
            font-size: 14px;
            font-weight: 600;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            color: rgb(var(--yellow));
          }

          .bet-entry__label {
            font-size: 10px;
            font-weight: 600;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            color: rgba(255, 255, 255, 0.65);
            text-transform: uppercase;
            white-space: nowrap;
          }
        }
      }

      .row {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .column {
        display: flex;
        flex-direction: column;

        span:last-child {
          color: rgba(255, 255, 255, 0.65);
          padding-top: 2px;
        }
      }
    }
  }
}

// LABELS
.card-label {
  position: relative;
  background: rgba(var(--marbles-container-dark-blue), 0.65);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 999em;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  user-select: none;

  svg {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    fill: rgb(var(--marbles-yellow));
    position: relative;
    z-index: 3;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
  }

  span {
    position: relative;
    z-index: 3;
    font-size: 12px;
    font-weight: 600;
  }
}

.card-label-latest-winners {
  padding: 0;
  display: flex;
  gap: 0;
  border-radius: 12px 24px 24px 12px;

  svg {
    width: 16px;
    height: 16px;
  }
  .result-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    box-sizing: border-box;
  }
  .results-marbles {
    display: flex;
    gap: 8px;
    padding: 8px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  .marble {
    position: relative;
    display: flex;
    width: 22px;
    height: 22px;
  }
}

// Buttons and buttons containers
.default-btn {
  background: linear-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05));
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0 15px;
  height: 30px;
  gap: 10px;
  cursor: pointer;
  transition: all 250ms ease-in-out;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);

  &:not(:only-child) {
    flex: 1;
  }

  svg {
    width: 14px;
    height: 14px;
    fill: currentColor;
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
  }
  @media screen and (max-width: 768px) and (pointer: coarse) {
    height: 36px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  span {
    position: relative;
    z-index: 3;
    font-size: 10px;
    font-weight: 600;
    font-family: var(--font-formula-stack);
    user-select: none;
  }

  &:active {
    transform: scale(0.95);
  }
  &:hover {
    color: rgb(var(--yellow));
    background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.05));
  }
  &.active {
    color: rgb(var(--yellow));
  }
  &.fullwidth {
    flex: 1;
  }
  &.center {
    justify-content: center;
  }
}

// Inline loader (for panels etc.)
.inline-loader {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
  margin: 15px;
  box-shadow:
    inset 0 1px 0 rgba(var(--yellow), 0.8),
    inset 0 -1px 2px rgba(var(--yellow), 0.6),
    inset 0 0 30px rgba(var(--yellow), 0.25);
  box-sizing: border-box;
  border-radius: 12px;
  background: rgba(var(--marbles-container-dark-blue), 0.75);
  .label {
    font-size: 12px;
    font-family: var(--font-formula-stack);
    font-weight: 600;
    color: rgb(var(--yellow));

    animation: text-fancy-color 1.5s linear infinite reverse;
    display: flex;
    background: linear-gradient(
      90deg,
      rgba(255, 234, 153, 1) 10%,
      rgba(var(--yellow), 1) 50%,
      rgba(255, 234, 153, 1) 60%
    );

    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% auto;
    color: rgba(var(--yellow), 1);
    -webkit-text-fill-color: transparent;
  }
  .spinner {
    display: block;
    width: 20px;
    height: 20px;
    background: url(/assets/marbles_symbol-white.webp) no-repeat center center;
    background-size: contain;
    animation: spin 2.5s infinite ease-in-out;
  }
}

// Loading indicator
.race-loading-indicator-outer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    animation: reveal-opacity 1s ease-in-out;

    border-radius: 10px;
    content: '';
    background: linear-gradient(rgba(var(--black-color), 0), rgba(var(--black-color), 0.8));
    position: absolute;
    background-size: cover;
    background-position: center top;
    left: 0;
    right: 0;
    top: 0;
    z-index: 3;
    bottom: 0;
  }
}

.loading-indicator__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  bottom: 0;
  background-size: cover;
  background-position: center top;
  animation: reveal-opacity 2s ease-in-out;
  border-radius: 10px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      circle,
      rgba(var(--black-color), 0.2) 0%,
      rgba(var(--black-color), 0.6) 85%,
      rgba(var(--black-color), 1) 95%
    );
    z-index: 999;
  }
  img,
  video {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}

.race-loading-indicator {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 320px;
  margin: auto;
  z-index: 9999;

  background: rgba(var(--primary-component-background), 0.9);
  box-shadow: var(--primary-background-shadow);

  border-radius: 28px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  animation: reveal-opacity 1s ease-in-out;

  &.orientation-message {
    .orientation-icon {
      margin-bottom: 10px;
      position: relative;
      height: 60px;
      width: 60px;

      svg {
        width: 60px;
        height: 60px;
        fill: rgb(var(--yellow));
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.4));
        position: absolute;
        animation: first-phone 3s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
      }
    }
  }

  @keyframes first-phone {
    0% {
      opacity: 0;
      transform: rotate(90deg);
    }
    15% {
      opacity: 1;
      transform: rotate(90deg);
    }
    50% {
      opacity: 1;
      transform: rotate(0deg);
    }
    85% {
      opacity: 1;
      transform: rotate(0deg);
    }
    100% {
      opacity: 0;
      transform: rotate(0deg);
    }
  }

  .race-loading-indicator__inner {
    display: flex;
    gap: 5px;
    padding: 20px 0;
    z-index: 9;
    box-sizing: border-box;
    border-radius: 28px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);

    position: relative;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: var(--font-formula-stack);
    font-weight: 600;
    color: rgb(var(--yellow));
    text-transform: uppercase;
    overflow: hidden;

    button {
      background: rgba(var(--white-color), 0.15);
      border: none;
      border-radius: 16px;
      width: 100%;
      padding: 16px;
      margin-top: 10px;
      font-family: var(--font-formula-stack);
      font-size: 12px;
      font-weight: 600;
      color: rgba(var(--white-color));
      appearance: none;
      user-select: none;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: rgba(var(--white-color), 0.25);
        color: rgba(var(--yellow));
      }
    }

    .text {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 0 20px;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 9;

      animation: reveal-opacity 1s ease-in-out;
      position: relative;

      .primary {
        font-size: 12px;

        animation: text-fancy-color 1.5s linear infinite reverse;

        background: linear-gradient(
          90deg,
          rgba(255, 234, 153, 1) 10%,
          rgba(var(--yellow), 1) 50%,
          rgba(255, 234, 153, 1) 60%
        );

        background-clip: border-box;
        background-clip: text;
        -webkit-background-clip: text;
        background-size: 200% auto;
        color: rgba(var(--yellow), 1);
        -webkit-text-fill-color: transparent;
        user-select: none;
      }
      .secondary {
        font-size: 12px;
        color: #fff;

        &.no-transform {
          text-transform: none;
        }
      }
    }

    span {
      z-index: 3;
    }
  }

  .ico-marbles-symbol_svg__ico-marbles-symbol {
    width: 60px;
    height: 60px;
    transition: all 500ms ease-in-out;
    transform-style: preserve-3d;
    transform-origin: center;
    animation: rotate-symbol-loading 3s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    position: relative;
    margin-bottom: 10px;
    z-index: 3;
  }

  @keyframes rotate-symbol-loading {
    0% {
      transform: rotate(0deg);
    }
    33% {
      transform: rotate(-40deg);
      scale: 1.05;
    }
    66% {
      transform: rotate(40deg);
      scale: 0.95;
    }
    80% {
      transform: rotate(0deg);
      scale: 0.8;
    }
    100% {
      transform: rotateY(180deg);
      scale: 1;
    }
  }

  .progress-bar-outer {
    padding: 5px 0 0;
  }
  .progress-bar {
    position: absolute;
    left: 20px;
    right: 20px;
    border-radius: 999em;
    bottom: 15px;
    height: 6px;
    background-color: rgba(var(--yellow), 0.8);

    background: linear-gradient(90deg, rgba(255, 234, 153, 1), rgba(var(--yellow), 1));
    transform: scaleX(0);
    transform-origin: left;
    animation: progress-animation 15s infinite;
  }

  @keyframes progress-animation {
    0% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
}

.default-btn {
  &.mobile-visible {
    display: none !important;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      display: flex !important;
    }
  }
  &.mobile-hidden {
    display: flex !important;

    @media screen and (max-width: 768px) and (pointer: coarse) {
      display: none !important;
    }
  }
}

.mobile-visible {
  display: none !important;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    display: block !important;
  }
}
.mobile-hidden {
  display: block !important;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    display: none !important;
  }
}

// MARBLES
.marble-outer {
  position: relative;
  display: flex;
  flex-direction: column;

  box-shadow:
    0 0 15px rgba(0, 0, 0, 0.35),
    0 0 2px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 999em;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  @media screen and (max-width: 390px) and (pointer: coarse) {
    height: 30px;
    width: 30px;
  }

  // &:hover {
  //   padding: 4px;
  // }
  &.active {
    padding: 5px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      border-top: 1px solid rgb(var(--green));
      border-radius: 999em 999em 0 0;
      transform: rotate(0deg);
      animation: rotate 5s infinite linear reverse;
      transform-origin: center bottom;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 1px solid rgb(var(--green));
      border-radius: 0 0 999em 999em;
      transform: rotate(0deg);
      animation: rotate 5s infinite linear;
      transform-origin: center top;
    }

    .marble_blur {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      right: 0;
      opacity: 0.9;
      border-radius: 999em;
      bottom: 0;
      pointer-events: none;
      user-select: none;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        left: 45%;
        right: 45%;
        top: 0;
        bottom: 0;
        background: rgba(var(--green), 1);
        filter: blur(5px);
        animation: rotate 5s infinite linear;
      }
    }
  }

  .marble {
    &.active {
    }
  }
  .number {
    z-index: 4;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
    user-select: none;
    pointer-events: none;
  }

  .checkmark {
    position: absolute;
    width: 18px;
    bottom: -6px;
    left: 50%;
    z-index: 4;
    height: 18px;
    border-radius: 99em;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition: opacity 250ms ease-in-out;

    &.active {
      border: 2px solid rgba(var(--primary-dark-color), 0.95);
      box-shadow:
        0 0 2px rgba(0, 0, 0, 0.25),
        inset 0 -2px 0 rgba(0, 0, 0, 0.35);
      background: linear-gradient(rgba(var(--green), 1) 0%, rgba(var(--dark-green), 1) 100%);
      opacity: 1;
    }
    svg {
      width: 12px;
      height: 12px;
      fill: #fff;
      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.25));
    }
  }
}
.marble {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(37, 41, 55, 1) 0%, rgba(255, 255, 255, 0.25) 100%);
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 600;
  color: #fff;

  .marble-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .marble-label-number {
    z-index: 3;
    font-size: 14px;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
  }

  &.no-tooltip {
    &::before {
      display: none;
    }
  }

  input {
    display: none;
  }

  // Color for tooltips
  $marble-colors: (
    'Midnight': var(--midnight),
    'Ocean': var(--ocean),
    'Atlas': var(--atlas),
    'Nemo': var(--nemo),
    'Princess': var(--princess),
    'Inferno': var(--inferno),
    'Toothpaste': var(--toothpaste),
    'Bee': var(--bee)
  );

  @each $name, $color in $marble-colors {
    &.marble-#{$name} {
      &::before {
        color: rgb($color);
      }
    }
  }

  &::before {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -10px);
    padding: 4px 8px;
    background: rgb(var(--primary-component-background));
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    color: rgb(var(--yellow));
    font-size: 12px;
    white-space: nowrap;
    border-radius: 4px;
    opacity: 0;
    transition:
      transform 250ms ease-in-out,
      opacity 250ms ease-in-out;
    pointer-events: none;
  }

  &:hover {
    color: rgb(var(--yellow));

    &::before {
      opacity: 1;
      transform: translate(-50%, -4px);
    }
  }

  &.marble-xs {
    width: 20px;
    height: 20px;
    font-size: 11px;
  }
}

// TOAST
.toast {
  width: 570px;
  -webkit-backdrop-filter: var(--primary-backdrop-filter);
  backdrop-filter: var(--primary-backdrop-filter);
  border-radius: 12px;
  box-shadow: var(--primary-background-shadow);
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  pointer-events: none;
  position: relative;

  @media (max-width: 768px) and (pointer: coarse) {
    width: 100%;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.toast__inside-container {
    width: auto;
    margin-bottom: 10px;

    .toast__inner {
      background: transparent;
      box-shadow: none;
      margin-bottom: 0;
      backdrop-filter: none;
      -webkit-backdrop-filter: none;
      box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
    }
  }
}
.toast__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: rgba(var(--primary-component-background), 0.8);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 15px 45px;
  position: relative;

  &.green-shadow {
    box-shadow: inset 0 1px 0 rgba(var(--green), 0.75);

    .toast__icon {
      svg {
        fill: rgb(var(--green));
      }
    }
  }

  &.red-shadow {
    box-shadow: inset 0 1px 0 rgba(var(--red), 0.75);

    .toast__icon {
      svg {
        fill: rgb(var(--red));
      }
    }
  }

  &.yellow-shadow {
    box-shadow: inset 0 1px 0 rgba(var(--yellow), 0.75);

    .toast__icon {
      svg {
        fill: rgb(var(--yellow));
      }
    }
  }

  .text {
    font-size: 11px;
    font-weight: 600;
    font-family: var(--font-formula-stack);
  }
  .toast__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 15px;
  }
  .toast__close-button {
    position: absolute;
    right: 6px;

    button {
      box-sizing: border-box;
      appearance: none;
      border: none;
      background: none;
      padding: 10px;
      margin: 0;
      &:hover {
        svg {
          fill: rgb(var(--yellow));
          transform: rotate(180deg);
        }
      }
      svg {
        width: 16px;
        height: 16px;
        fill: currentColor;
        transition: all 250ms ease-in-out;
      }
    }
  }
}

// Show players
.left-floating-panel {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 99;
  gap: 8px;
  display: flex;
  flex-direction: column;
  transform-origin: left top;
  align-items: flex-end;
  height: 264px;

  @media screen and (max-width: 768px) and (pointer: coarse) {
    top: 60px;
    height: auto;
  }
}
